import { Box, BoxProps, SxProps, styled } from "@mui/material";
import React, { MouseEvent, useCallback, useContext, useEffect, useState } from "react";
import {
  BestOnlineCasinoApp,
  BigBrandLogo,
  BrandLogo,
  FloatingLineButton,
  IconAeSexy,
  IconAsiaGaming,
  IconCQ9Gaming,
  IconJoker,
  IconPG,
  IconPlayNGo,
  IconPlayStar,
  IconPragmaticPlay,
  IconRowThree,
  IconSBOBet,
  IconSimplePlay,
  LoginButtonImage,
  OnlineSlots,
  RegisterButtonImage,
  SlotGames,
  SlotMachine,
} from "src/assets";
import { AppContext } from "src/main/constants";
import useGtmTracker, { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles, isMobile } from "src/main/utils";

interface LoginProps extends React.PropsWithChildren {}

interface LogoBoxProps {
  sx: SxProps | undefined;
  alt: string | undefined;
  path: string | undefined;
}
const LogoBox = (props: LogoBoxProps) => {
  const { sx = {}, alt = "logo", path } = props;
  return (
    <Box sx={sx}>
      <img
        alt={alt}
        src={path}
        style={{ width: "100%" }}
      />
    </Box>
  );
};

const JumpyBox = styled(Box)<BoxProps>(() => ({
  "@keyframes jumpText": {
    "0%": {
      width: "90%",
    },
    "50%": {
      width: "100%",
    },
    "100%": {
      width: "90%",
    },
  },
  img: {
    alignSelf: "center",
    width: "90%",
    animation: "jumpText 2s ease infinite",
  },
  justifyContent: "center",
  display: "flex",
}));

const FlexImageBox = styled(Box)<BoxProps>(() => ({
  img: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  justifyContent: "center",
  display: "flex",
}));

const LandingPage: React.FC<LoginProps> = (props) => {
  const { sendEvent } = useGtmTracker();

  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};
  const [offsetRight, setOffsetRight] = useState(window.innerWidth > 600 ? (window.innerWidth - 600) / 2 : 20);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setOffsetRight(window.innerWidth > 600 ? (window.innerWidth - 600) / 2 : 20);
    });
  }, []);

  const maxWidth = window.innerWidth > 600 ? 600 : window.innerWidth;
  const lineRightTranslate = window.innerWidth > 600 ? 0 : Math.ceil((maxWidth / 3) * 0.15);

  const handleClick = useCallback(
    (type: "login" | "register", sideAction?: boolean) => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        if (sideAction) {
          sendEvent(EvtAction.ClickLogin);
        }
        window.open(loginUrl, "_blank", isMobile() ? "width=full,heigh=full" : "");
      } else {
        if (sideAction) {
          sendEvent(EvtAction.ClickRegister);
        }
        window.open(registerUrl, "_blank", isMobile() ? "width=full,heigh=full" : "");
      }
    },
    [loginUrl, registerUrl, sendEvent],
  );

  return (
    <Box sx={styles.root}>
      <JumpyBox
        display="flex"
        flexDirection="column"
        height="10vh"
      >
        <img
          alt="online slots"
          src={OnlineSlots}
          style={{ alignSelf: "center", width: "100%" }}
        />
      </JumpyBox>

      <FlexImageBox height="25vh">
        <img
          alt="moo555 brand logo"
          src={BigBrandLogo}
          style={{ alignSelf: "center" }}
        />
      </FlexImageBox>

      <Box
        display="flex"
        height="15vh"
        width="100%"
      >
        <Box
          display="flex"
          width="100%"
          height="100%"
        >
          <Box
            flex={1}
            height="100%"
            width="33%"
          />
          <Box
            flex={1}
            height="100%"
            width="33%"
          >
            <FlexImageBox height="100%">
              <img
                alt="slot machine"
                src={SlotMachine}
                style={{ height: "100%" }}
              />
            </FlexImageBox>
          </Box>
          <Box
            flex={1}
            height="100%"
            width="33%"
          />
        </Box>
      </Box>

      <img
        alt="best online casino app"
        src={BestOnlineCasinoApp}
        style={{ alignSelf: "center", maxWidth: 360, width: window.innerWidth }}
      />

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          gap: 1,
          justifyContent: "center",
        }}
      >
        <Box
          component="a"
          href={loginUrl}
          target="_blank"
          onClick={handleClick("login", true)}
          sx={styles.loginButton}
        />
        <Box
          component="a"
          href={registerUrl}
          target="_blank"
          onClick={handleClick("register", true)}
          sx={styles.registerButton}
        />
      </Box>

      <Box flexGrow={1} />
      <img
        alt="slot games"
        src={SlotGames}
        style={{
          alignSelf: "center",
          maxHeight: "25vh",
          maxWidth: "100%",
          transform: "translateY(-2vh)",
        }}
      />

      <Box sx={styles.textContent}>
        <p>แนะนำคาสิโนออนไลน์ Moo555</p>
        <p>
          ยินดีต้อนรับสู่ Moo555 ที่เป็นที่นี่แห่งการบันเทิงคาสิโนออนไลน์ที่ยอดเยี่ยมของคุณ!
          คาสิโนออนไลน์ของเรามุ่งเน้นให้คุณได้สัมผัสประสบการณ์การเล่นเกมที่ปลอดภัย ใช้ง่าย และมีความสนุกสนาน
          ไม่ว่าคุณจะเป็นผู้เล่นที่มีประสบการณ์หรือเป็นคนใหม่ในโลกการพนันออนไลน์{" "}
          <a
            href={loginUrl}
            onClick={handleClick("login")}
          >
            Moo555.com
          </a>{" "}
          มีสิ่งพิเศษรอคุณอยู่เสมอ
        </p>
        <p>
          ที่ Moo555 เราภูมิใจในการนำเสนอเกมสล็อตที่หลากหลายเพื่อตอบสนองความชื่นชอบและความสนใจที่หลากหลาย
          ตั้งแต่เกมสล็อตแบบคลาสสิกไปจนถึงเกมสล็อตวิดีโอที่มาพร้อมคุณลักษณะพิเศษ
          คอลเลคชันที่หลากหลายของเราให้คุณมีเกมที่เหมาะกับอารมณ์ของคุณเสมอ ก่อนที่คุณจะพาตัวเองเข้าสู่ความตื่นเต้น
          ให้เรามาสำรวจวิธีเข้าถึง{" "}
          <a
            href={loginUrl}
            onClick={handleClick("login")}
          >
            Moo555.com
          </a>{" "}
          ได้อย่างง่ายดาย
        </p>

        <a
          href={loginUrl}
          onClick={handleClick("login")}
        >
          <img
            alt="brand logo"
            src={BrandLogo}
            style={{ width: "100%", objectFit: "contain" }}
          />
        </a>

        <p>การเข้าถึงคาสิโนออนไลน์ Moo555</p>
        <p>
          เราเข้าใจความสำคัญของประสบการณ์การเล่นเกมที่ไม่มีปัญหาและไม่ยุ่งยาก
          ด้วยเหตุนี้เราทำให้ง่ายมากที่สุดในการเข้าถึงคาสิโนออนไลน์ Moo555 นี่คือวิธีที่คุณสามารถเริ่มต้นได้ง่ายๆ
        </p>
        <ol>
          <li>
            เข้าชม{" "}
            <a
              href={loginUrl}
              onClick={handleClick("login")}
            >
              Moo555.com
            </a>
            : เปิดเบราว์เซอร์ที่คุณชื่นชอบและพิมพ์ "moo555.com" ในแถบที่อยู่ของเบราว์เซอร์ กด Enter
            เพื่อเข้าถึงเว็บไซต์ของเรา
          </li>
          <li>
            เข้าสู่ระบบ: หากคุณเป็นสมาชิกที่ลงทะเบียนแล้ว คลิกที่ปุ่ม "เข้าสู่ระบบ" ที่ตั้งอยู่บนมุมขวาบนของหน้าหลัก
            เพื่อกรอกข้อมูลเข้าสู่ระบบและเริ่มเล่น
          </li>
          <li>
            การลงทะเบียนสมาชิกใหม่: สำหรับคนที่เป็นมือใหม่กับ Moo555 คลิกที่ปุ่ม "ทางเข้า" เพื่อสร้างบัญชีของคุณ
            กรอกข้อมูลที่จำเป็น ปฏิบัติการลงทะเบียนอย่างง่ายดายและคุณก็พร้อมที่จะเพลิดเพลินกับเกมสล็อตของเราได้ทันที
          </li>
          <li>
            สำรวจเกมสล็อต: เมื่อคุณเข้าสู่ระบบหรือลงทะเบียนแล้ว สำรวจคอลเลคชันของเกมสล็อตของเราได้โดยคลิกที่แท็บ "สล็อต"
            จากนั้นคุณสามารถเลือกเล่นเกมต่างๆ ที่มีหัวข้อที่น่าสนใจ คุณลักษณะที่แตกต่าง และโอกาสในการชนะรางวัลใหญ่
          </li>
        </ol>
        <p>
          ทำไมคุณควรเลือก{" "}
          <a
            href={loginUrl}
            onClick={handleClick("login")}
          >
            Moo555.com
          </a>
          ?
        </p>
        <p>ตอนนี้ที่คุณรู้วิธีเข้าถึงคาสิโนออนไลน์ Moo555 มาเรามาพูด</p>

        <Box
          display="flex"
          width="100%"
        >
          <LogoBox
            alt="brand logo"
            path={IconPG}
            sx={{ flex: 1 }}
          />
          <LogoBox
            alt="brand logo"
            path={IconAeSexy}
            sx={{ flex: 1 }}
          />
          <LogoBox
            alt="brand logo"
            path={IconJoker}
            sx={{ flex: 1 }}
          />
          <LogoBox
            alt="brand logo"
            path={IconSBOBet}
            sx={{ flex: 1 }}
          />
          <LogoBox
            alt="brand logo"
            path={IconCQ9Gaming}
            sx={{ flex: 1 }}
          />
        </Box>

        <Box
          display="flex"
          width="100%"
          gap={0.1}
        >
          <LogoBox
            alt="brand logo"
            path={IconAsiaGaming}
            sx={{ flex: 1 }}
          />
          <LogoBox
            alt="brand logo"
            path={IconSimplePlay}
            sx={{ flex: 1 }}
          />
          <LogoBox
            alt="brand logo"
            path={IconPlayStar}
            sx={{ flex: 1 }}
          />
          <LogoBox
            alt="brand logo"
            path={IconPragmaticPlay}
            sx={{ flex: 1 }}
          />
          <LogoBox
            alt="brand logo"
            path={IconPlayNGo}
            sx={{ flex: 1 }}
          />
        </Box>

        <img
          alt="brand logo"
          src={IconRowThree}
          style={{ width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          position: "fixed",
          height: "max(12vh, 120px)",
          bottom: "10px",
          right: offsetRight,
          justifyContent: "right",
          transform: `translateX(${lineRightTranslate}px)`,
        }}
      >
        <Box
          display="flex"
          sx={{
            height: "100%",
            width: "100%",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
        >
          <img
            onClick={() => window.open("https://m555th.com/cs", "_blank", isMobile() ? "width=full,height=full" : "")}
            alt="fab line contact us"
            src={FloatingLineButton}
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              alignSelf: "flex-end",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = createStyles({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  loginButton: {
    background: `url(${LoginButtonImage})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "max(12vh, 104px)",
    width: "max(24vh, 210px)",
    maxWidth: "cal(100% - 5px)",
    cursor: "pointer",
    overflowX: "hidden",
  },
  registerButton: {
    background: `url(${RegisterButtonImage})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "max(12vh, 104px)",
    width: "max(24vh, 210px)",
    maxWidth: "cal(100% - 5px)",
    cursor: "pointer",
  },
  textContent: {
    marginX: "-16px",
    "@media (min-width: 600px)": {
      marginX: "-24px",
    },
    marginTop: "-100px",
    padding: "20px",
    paddingTop: "80px",
    paddingBottom: "130px",
    textAlign: "center",
    color: "#fff",
    fontSize: "15px",
    fontWeight: "400",
    background: "radial-gradient(137.78% 100% at 50% 0%, #4E4E4E 0%, #000 50%)",
    fontFamily: "'Kanit'",

    "& a": {
      color: "white",
      textDecoration: "none",
    },

    ol: {
      textAlign: "left",
      listStyle: "none",
      counterReset: "my-awesone-counter",
      listStylePosition: "outside",
      ml: -2,
      li: {
        position: "relative",
        counterIncrement: "my-awesome-counter",
        margin: "1rem",
        paddingLeft: "1rem",
        listStylePosition: "outside",
        "::before": {
          position: "absolute",
          top: "4px",
          transform: "translateX(-20px)",
          left: 0,
          content: "counter(my-awesome-counter)",
          lineHeight: "2rem",
          color: "#FC80EE",
          fontSize: "20px",
          fontWeight: "bold",
        },
        ":after": {
          content: '""',
          top: "22px",
          transform: "translateX(-20px)",
          left: "12px",
          position: "absolute",
          height: "4px",
          width: "4px",
          backgroundColor: "#FC80EE",
          borderRadius: "100%",
        },
      },
    },
  },
});

export default LandingPage;
